.job-description{
	h4{
		margin-top: 2rem;
    	margin-bottom: 1rem;
	}
	p{
		line-height: 1.6;
	}
	ul li{
		margin-bottom: 0.3rem;
		line-height: 1.6;
    //list-style: none;
	}
}
.nowrap{
  white-space:  nowrap;
}
label{
  font-size: 1rem;
}
#apply-form{
  legend{
    font-size: 1.4em;
  }
  [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'] {
    height: 3rem;
    padding-top: 0.5rem; 
    padding-bottom: 0.5rem; 
        margin: 0 0 1.5rem;
  }
  textarea{
    height: auto;
    padding-top: 0.5rem; 
    padding-bottom: 0.5rem; 
    resize: vertical; 
  }
  .button-input{
        margin: 0;
    padding: 0;
    min-width: 0;
    min-height: 0;
    font-size: 1em;
    line-height: 1;
    height: 48px;
    width: 48px;
    margin-left: 0.5em;
    flex-shrink: 0;

    color: #175298;
border-color: #175298;
-webkit-transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
    &:hover{
    border-color: #175298;
    background:  #175298;
    color: #fff;
    }
  }
  
}


.button.button--shikoba.button--size-l{
  font-size: 1rem !important;
}

.dropzone {
  border: 2px dashed #dedede;
  border-radius: 5px;
  background: #f5f5f5;
  text-align: center;
  padding:2.5em 1em;

  .svg-inline--fa{
    color: rgba(0,0,0,.3);
    font-size: 3em;
  }
  .dz-msg {
    color: rgba(0,0,0,.54);
    font-weight: 500;
    font-size: initial;
    //text-transform: uppercase;
    margin-top: 0.6em;
    line-height:1.4;
    .dz-instruction{
      
       
      max-width: 70vw;
      text-overflow: ellipsis;
      overflow: hidden;


      .upper{
        text-transform: uppercase;
      }
    }
    .dz-file{
      display: block;
      width: 100%;
      text-transform: uppercase;
    }
  }
}

.required{
  color:red;
}
 
 .checkbox{
  margin-top: 1.5em;
 }


.ReactModal__Overlay{
  z-index: 1;

}

.ReactModal__Content{

}

.job-post {
    margin-bottom: 3em;
    &:last-child{
      margin-bottom: 0;
    }
    &__description{
      color:black;
    }
}
