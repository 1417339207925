.b-color{
	background-color:rgba(39,79,125,1);
	background-blend-mode: multiply;
	background-size:cover;
	background-repeat:no-repeat;
}

.bg-1{
	background-image:url('../img/common/bg/2862.jpg');
}
.bg-2{
	background-image:url('../img/common/bg/3147.jpg');
}
.bg-3{
	background-image:url('../img/common/bg/17580.jpg');
}
.bg-4{
	background-image:url('../img/common/bg/18408.jpg');
}
.bg-5{
	background-image:url('../img/common/bg/25100.jpg');
}