.benefits-list {
  display: flex;
  gap: 0.7em;

  

  &__icon {
    height: 60px;
  }
  @media screen and (max-width:640px) {
    align-items: center;
    
  }
}