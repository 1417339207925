//Careers
#careers-jobs {
  h2 {
    margin-bottom: 1em;
  }
}



a:has(.job-post) {
  transition: color 0.3s;

  &:hover {
    color: #0a6fee;
  }
}

.job-post {
  margin-bottom: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;


  .title-icon {
    height: 1.255em;
  }




  // @media screen and (min-width:650px) {

  //   .title-icon {
  //     translate: -22px 19px;
  //     transition: translate 0.3s, opacity 0.3s;
  //     opacity: 0;
  //   }
  // }

  // &:hover {
  //   .title-icon {
  //     translate: 0;
  //     opacity: 1;
  //   }
  // }
}

#careers-encourage {}

#careers-intro {
  .fixed-img {
    background-image: url('../images/coding-s.jpg');

    @media (min-width: 640px) {
      background-image: url('../images/coding-m.jpg');
    }

    @media (min-width: 960px) {
      background-image: url('../images/coding-l.jpg');
    }
  }
}

#careers-how {
  .fixed-img {

    background-image: url('../images/working-m.jpg');

    @media (min-width: 960px) {
      background-image: url('../images/working-l.jpg');
    }
  }

  ul {
    list-style: none;
    margin-left: 1rem;
  }
}


#apply-form {
  .button {
    @extend .fs-7;
  }
}

.job-description {

  margin: 2em 0;

}

.button.upload-cv {
  background-color: $second-blue;
  background-color: rgba(39, 65, 125, 0.7);
}






.bt-gray {
  border-top: 1px solid $light-gray;
  margin-top: 1rem;
  padding-top: 1rem;
}

.bb-gray {
  border-bottom: 1px solid $light-gray;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

// dashboard
#dashboard {
  background: #f9fcff;
  padding: 2em 0;

  .button {
    @extend .fs-7;
    margin-bottom: 0;
  }

  p {
    line-height: 1.7;
    font-size: 1rem;
  }

  .subtitle {
    font-size: 1.25rem;
  }

  .test-note {
    //font-weight: bold;
    @extend .fs-7;

    //margin-bottom: 0.3em;
  }

  .test-timer {
    //margin: 3em 0;
  }

  .test-timer__time {
    @extend .fs-3;
    @extend .fw-1;
    //line-height: 1;
  }

  .test-timer__label {
    @extend .fs-7;
    //margin-bottom: 0.3em;
  }
}

.dashboard-event {
  //margin-bottom: 3em;
}

.dashboard-event__date {
  @extend .fs-9;
  margin-bottom: -0.5em;
}

.dashboard-event__title {
  @extend .fs-6;
  //line-height: 1.2;
  //margin-bottom: 0.5em;
  @extend .bb-gray;

}



.dashboard-event__content {
  @extend .fs-8;

}

#dashboard-note {

  padding: 2em;

}




.candidate-letters {
  width: 70px;
  height: 70px;
  background: $primary;
  border: 1px solid darken($primary, 7);
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.5em;
  border-radius: 50%;
  margin: 0.6em auto;

  font-weight: 600;
}

.candidate-name {
  font-size: 1.2em;
  color: $primary;
  line-height: 1em;
  margin-bottom: 0.3em;
  font-weight: 500;
}

.job-name {
  font-size: 0.9em;
  margin-bottom: 2em;
  font-weight: 600;
}

.job-description {
  text-align: left;
  margin-bottom: 0;

}

.dashboard-info {
  text-align: left;
  font-size: 0.9em;
  @extend .bt-gray;
}

.dashboard-status {

  .svg-inline--fa,
  .la,
  .las,
  i {
    font-size: 3em;
    opacity: 0.2;
  }

}


.status-data {
  margin-bottom: 0;
}

.status-label {
  margin-top: -0.3em;
  font-size: 0.7em;
  text-transform: uppercase;
}