.mt-0 {
  margin-top: 0;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.d-block {
  //display: block !important;
  max-height: none !important;
  opacity: 1 !important;
}

.section--widemargin {

  padding: 10rem 1em;
}




@media (max-width: 960px) and (min-width: 641px) {
  @each $value in $font-list {}
}

@media (max-width: 640px) {
  @each $value in $font-list {
    .section--widemargin {
      padding: 5rem 1em;
    }

    #contact {

      padding: 1em;

    }

    .job-description {
      h4 {
        font-size: 1.5em;
      }
    }

    #apply-form {
      //font-size: 1.5em;
    }
  }
}

.threequarter-section {
  //height: 400px;
  min-height: 75vh;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  .row {
    height: 100%;
  }

  @media (max-width: 639px) {
    //min-height:100vh;
  }
}

.job-apply-title-section {
  height: 22em;
  display: flex;

}

.min-threequarter-section {
  min-height: 75vh;
  display: flex;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
  -webkit-appearance: textfield;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}


.small-modal {
  max-width: 40%;

  @media (max-width: 639px) {

    max-width: none;


  }
}

.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;

  .ReactModal__Content {
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;

    position: relative !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    min-width: 40%;
    margin: 2em;
    transform: none;

    .modal-title {
      text-align: center;
      padding-bottom: 1em;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .modal-content {
      //margin-bottom: 0;
      margin-bottom: 0.4em;
      line-height: 1.5;

    }

    a {
      margin-bottom: 0;
    }

    .input-container {
      margin-top: 0 !important;
    }
  }
}


.top-bar {

  //position: fixed;


}

.card {
  background: white;
  padding: 1.5em;
  border-radius: 4px;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.051), 0px 3px 6px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.051), 0px 3px 6px rgba(0, 0, 0, 0.06);
  margin-bottom: 1em;
}


.sticky-container {

  z-index: 2;
}



.b-color {
  color: white;
  background-size: 100% 100%;
}





.scrolling-pill {
  text-transform: uppercase;
  min-width: 266px;
  position: absolute;
  bottom: 30px;
  border-radius: 50px;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid;
  padding: 1em 30px 1em 20px;
  overflow: hidden;
  background: #fff !important;
  color: #37474f;
  color: #175298 !important;
  border-color: white;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s, translate 0.7s;

  animation: .7s ease-out 1s 1 slidein;

  @keyframes slidein {
    from {
      bottom: -20px;
      opacity: 0;
    }

    to {
      bottom: 30px;
      opacity: 1;
    }

  }

  opacity: 1;


  &:hover {
    translate: 0 -10px;
    color: #fff !important;
    background: #7986cb;
    background: #175298 !important;
    border-color: white !important;
    background: transparent !important;



  }


  .svg-inline--fa {
    display: var(--fa-display, inline-block);
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
    left: 20px;
    font-size: 18px !important;
    transform: translate3d(0, 0, 0);
    margin-right: .7em;
  }

}