
.top-bar{

  backdrop-filter: blur(10px);

    .top-bar-title {
        
        padding: 0.5rem 1rem;
        position:relative;
        z-index: 9999;
    }
    .logo{
      background: url(../img/common/logo-white.png);
      background-size:contain;
      background-repeat: no-repeat;
      width: 100px;
      height:0px;
      padding-bottom: 35%;
      transition: all 0.8s;
      
    }
    
    .menu-icon{
      margin: 20px;
      width: 20px;
        height: 16px;
      
    }
   
    padding:0;


  &.no-white, &.white.no-white,  &.white.no-white.white-again.no-white-again {
  	background: rgba(255,255,255,0.5);
  	@media (max-width: 639px){
  		background: rgba(255,255,255,0.9);
  	}
  
  	
  	.logo{
  		background: url(../img/common/logo-color.png);
  		background-size:contain;
  		background-repeat: no-repeat;
  		
  	}
  	
  	.menu-icon{
  		
  		&::after {
  	     
  		    background: #000;
  		    box-shadow: 0 7px 0 #000, 0 14px 0 #000;
  		 
  		}
  	}
   
  	ul{
      
      li{


        a{
          color: black;
          transition: all 0.8s;
        }
      }
    }

  }
		 
	&.white,&.white.no-white.white-again{
		background: rgba(0,0,0,0.3);
		@media (max-width: 639px){
			background: rgba(0,0,0,0.6);

		}

		li{
			a{
				color: white;
        &.active{
          font-weight: 700;
        }
			}
		}
		&.is-anchored{
			background: transparent;
			.logo{
			 
				width: 150px;
			 	&.careers{
					 
				    width: 320px;
				    height: 0px;
				    padding-bottom: 17%;
					transition: all 0.8s;
				}
			}
			@media (max-width: 639px){
				.top-bar-right{
					background: rgba(0, 0, 0, 0.5);
				    padding-top: 110px;
				    margin-top: -110px;
				    z-index: -1;
				    position: relative;
				}
			}
		}
		.logo{
			background: url(../img/common/logo-white.png);
			background-size:contain;
			background-repeat: no-repeat;
			&.careers{
				background: url(../img/common/logo-white-careers.png);
				background-size:contain;
				background-repeat: no-repeat;
				 
			}
		}
		.menu-icon::after {
		     
		    background: #fefefe;
		    box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
		 
		}

	 
	}
  ul{
      background: transparent;
      text-align: right;
      @media (max-width: 639px){
        font-size:120%;
        li{
          display: block;
          text-align: center;
        }
      }
    
    }
	
}

#careers-intro{
  .button{
    float:none;
    display: inline-block;
  }
}
.responsive-menu-icon{
    display: none;
    z-index: 999;

    position: relative;
      @media (max-width: 639px){
        
          display: block;

          
      }
}
#responsive-menu{
  transition: all 0.7s;
  position: absolute;
    width: 100%;
  @media (max-width: 639px){
          opacity: 0;
           //display: none;
           .top-bar-right{
            transition: all 0.7s;
            //margin-left:-100vw;
            margin-top: -400px;
           }
          
          //overflow: hidden;
          //position: absolute;
      }
}

#responsive-menu.d-block{
  
      @media (max-width: 639px){
          opacity: 1;
           //display: none;
           .top-bar-right{
            //margin-left:0vw;
            margin-top: 0px

           }
          
          //overflow: hidden;
      }
  
}